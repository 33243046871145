<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <vxe-form v-if="!readType" class="" ref="anyForm" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
        <vxe-form-item title="產品" field="ProductId" :item-render="{}" span="24">
          <template #default="{ data }">
            <select-box v-bind="selectorOptions" :focusRefresh="true" @change="customerProduct(data)" v-model="data.ProductId" />
          </template>
        </vxe-form-item>
        <vxe-form-item title="箱數" field="Quantity" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.Quantity" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="套/箱" field="Radix" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.Radix" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="總下單件數" field="OrderQuantity" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.OrderQuantity" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="總卷數" field="RollQuantity" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.RollQuantity" placeholder="請輸入數字" ></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="總棧板數量" field="Pallets" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.Pallets" placeholder="請輸入數字" ></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="@G.W" field="GW" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.GW" :digits="2" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="TotleG.W" field="TotleGW" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.TotleGW" :digits="2" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="@N.W" field="NW" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.NW" :digits="2" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="TotleN.W" field="TotleNW" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.TotleNW" :digits="2" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="總重量" field="TotalWeight" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.TotalWeight" :digits="2" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <!-- <vxe-form-item title="品號" field="ProductNo" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.ProductNo" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="產品說明" field="ProductDescription" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.ProductDescription" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="產品尺寸" field="ProductSize" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.ProductSize" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="包裝說明" field="PackagingDescription" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.PackagingDescription" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
        <!-- <vxe-form-item title="箱/卷" field="Radix" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.Radix" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
        <vxe-form-item title="CBM (立方米)" field="CBM" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.CBM" :digits="4" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <!-- <vxe-form-item title="顯示厚度" field="ThicknessDisplay" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.ThicknessDisplay">
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item> -->
        <!-- <vxe-form-item title="單位價格" field="UnitPrice" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.UnitPrice" :digits="3" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
        <!-- <vxe-form-item title="長 (cm)" field="Long" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.Long" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="寬 (cm)" field="Width" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.Width" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="高 (cm)" field="High" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.High" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
        <!-- <vxe-form-item title="總金額" field="TotleAmount" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.TotleAmount" :digits="3" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
        <vxe-form-item title="備註" field="Remark" :item-render="{}" span="24">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Remark" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item :item-render="{}" span="3">
          <template #default>
            <vxe-button type="submit" status="primary" :content="table.isEditing ? '修改' : '確認'">
            </vxe-button>
            <vxe-button v-if="table.isEditing" type="button" @click="table.reset" status="danger" content="取消">
            </vxe-button>
          </template>
        </vxe-form-item>
    </vxe-form>
    <vxe-table class="mb-2" ref="grid" :data="table.data" v-bind="gridOptions.props">
                       <!-- ProductNo 品號
                            ProductDescription 產品說明
                            ProductSize 產品尺寸
                            PackagingDescription 包裝說明
                            OrderQuantity 總下單件數
                            Radix 箱/卷
                            Quantity 箱/卷數
                            UnitPrice 單位價格
                            TotleAmount 總金額
                            GW @G.W
                            CBM CBM (立方米)
                            TotleGW TotleG.W
                            Remark 備註
                            Long 長 (cm)
                            Width 寬 (cm)
                            High 高 (cm) -->
      <vxe-column field="Ordinal" title="項次" sortable>
      </vxe-column>
      <vxe-column field="Product.ProductNo" title="品號" sortable>
      </vxe-column>
      <vxe-column field="Product.ProductName" title="品名" sortable>
      </vxe-column>
      <vxe-column field="Quantity" title="箱數" sortable>
      </vxe-column>
      <vxe-column field="Radix" title="套/箱" sortable>
      </vxe-column>
      <vxe-column field="OrderQuantity" title="總下單件數" sortable>
      </vxe-column>
      <vxe-column field="RollQuantity" title="總卷數" sortable>
      </vxe-column>
      <vxe-column field="Pallets" title="總棧板數量" sortable>
      </vxe-column>
      <vxe-column field="GW" title="@G.W" sortable>
      </vxe-column>
      <vxe-column field="TotleGW" title="TotleG.W" sortable>
      </vxe-column>
      <vxe-column field="NW" title="@N.W" sortable>
      </vxe-column>
      <vxe-column field="TotleNW" title="TotleN.W" sortable>
      </vxe-column>
      <!-- <vxe-column field="ProductNo" title="品號" sortable>
      </vxe-column>
      <vxe-column field="ProductDescription" title="產品說明" sortable>
      </vxe-column>
      <vxe-column field="ProductSize" title="產品尺寸" sortable>
      </vxe-column>
      <vxe-column field="PackagingDescription" title="包裝說明" sortable>
      </vxe-column> -->
      <!-- <vxe-column field="Radix" title="箱/卷數" sortable>
      </vxe-column> -->
      <vxe-column field="TotalWeight" title="總重量" sortable>
      </vxe-column>
      <vxe-column field="CBM" title="CBM (立方米)" sortable>
      </vxe-column>
      <vxe-column field="ThicknessDisplay" title="顯示厚度" sortable :formatter="({ cellValue }) => cellValue == true ? '是' : cellValue == false ? '否' : '不選擇'">
      </vxe-column>
      <!-- <vxe-column field="UnitPrice" title="單位價格" :formatter="({ cellValue }) => formatAmount(cellValue)" sortable>
      </vxe-column> -->
      <!-- <vxe-column field="TotleAmount" title="總金額" :formatter="({ cellValue }) => formatAmount(cellValue)" sortable>
      </vxe-column> -->
      <vxe-column field="Remark" title="備註" sortable>
      </vxe-column>
      <!-- <vxe-column field="Long" title="長 (cm)" sortable>
      </vxe-column>
      <vxe-column field="Width" title="寬 (cm)" sortable>
      </vxe-column>
      <vxe-column field="High" title="高 (cm)" sortable>
      </vxe-column> -->
      <vxe-column v-if="!readType" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import { formatAmount, formatTotalAmount, formatCBMAmount } from '@/libs/utils'

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    customerId: Number,
    item: [],
    visible: Boolean,
    readType: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: [{ field: 'Ordinal', order: 'asc' }]
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        // console.log(row)
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();

        emit('updateTotal');
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {};
        table.formData.OrderQuantity = 0;
        // table.formData.Radix = 0;
        table.formData.Quantity = 0;
        table.formData.UnitPrice = 0;
        table.formData.TotleAmount = 0;
        table.formData.GW = 0;
        table.formData.TotleGW = 0;
        table.formData.NW = 0;
        table.formData.TotleNW = 0;
        table.formData.CBM = 0;
        table.formData.Pallets = 0;
        table.formData.Radix = 0;
        table.formData.TotalWeight = 0;
        table.formData.RollQuantity = 0;
        table.formData.ThicknessDisplay = false;
        // table.formData.Long = 0;
        // table.formData.Width = 0;
        // table.formData.High = 0;
        table.formData.Ordinal = 0;
        table.selectedRow = null;
        table.isEditing = false;
      },
      async save() {
        //table.formData.ProductSize = `${table.formData.Long ? Number(table.formData.Long) : 0},${table.formData.Width ? Number(table.formData.Width) : 0},${table.formData.High ? Number(table.formData.High) : 0}`
        table.formData.Product = null;
        if(!table.formData.ProductId)
          return CloudFun.send('error', { subject: '執行失敗', content: '請選擇產品' })
        if (table.formData.ProductId) {
          const temp = await model?.dispatch("product/find", table.formData.ProductId)
          table.formData.Product = { Id: temp.Id, ProductName: temp.ProductName, ProductNo: temp.ProductNo };          
        }        
        if(table.isEditing) {
          // 編輯確認
          await Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          table.formData.Ordinal = getNextOrdinal();
          await grid.value.insert(table.formData);
        }
        table.reset();
        await grid.value.sort("Ordinal", 'asc');

        emit('updateTotal');
      },
      rules: {
        ProductNo: [{ required: true }],
        ProductName: [{ required: true }],
      }
    })

    const getNextOrdinal = () => {
      const data = grid.value.getTableData().fullData;
      if(!data || data.length == 0) return 1; 
      var maxOrdinal = Math.max(...data.map((e: any) => e.Ordinal));
      return (maxOrdinal ?? 0) + 1;
    }

    watch(() => props.aid, async(value) => {
      if(value) {
        // console.log(props.data)
        var t = Date.now();
        if(props.data && props.data.length > 0) {          
          table.data = props.data;
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    watch(() => props.item, async(value: any) => {
      if(value && value.length > 0) {
        for (let item of value) {
          await new Promise(resolve => {
            table.formData.ProductId = item.Product.Id;
            table.formData.TotleAmount = item.TotleAmount;
            table.formData.OrderQuantity = item.OrderQuantity;
            table.formData.Quantity = item.Quantity;
            table.formData.Remark = item.Remark;
            table.formData.UnitPrice = item.UnitPrice;
            // table.formData.ThicknessDisplay = item.ThicknessDisplay;
            table.formData.RollQuantity = item.RollQuantity;
            // table.formData.CBM = item.CBM;
            table.formData.Pallets = item.Pallets;
            table.formData.Radix = item.Radix;
            table.formData.GW = item.Product?.GW ?? 0;
            table.formData.NW = item.Product?.NW ?? 0;
            table.isEditing = false;
            setTimeout(() => {
              resolve(table.formData.CBM);
            }, 200);
          });
          await table.save();
        }
        // await value.forEach(async(e: any) => {
        //   table.formData.ProductId = e.Product.Id;
        //   // table.formData.ProductNo = e.Product?.ProductNo;
        //   // table.formData.ProductDescription = e.Product?.ProductDescription;
        //   // table.formData.PackagingDescription = e.Product?.PackagingDescription;
        //   // table.formData.ProductSize = e.Product?.ProductSize;
        //   // table.formData.UnitPrice = e.Product?.UnitPrice;
        //   table.formData.TotleAmount = e.TotleAmount;
        //   // table.formData.Radix = e.Product?.Radix;
        //   table.formData.OrderQuantity = e.OrderQuantity;
        //   table.formData.Quantity = e.Quantity;
        //   table.formData.Remark = e.Remark;
        //   table.isEditing = false;
        //   console.log(table.formData.ProductId, 'go1')
        //   await table.save();
        // });
        
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "ProductName",
      valueField: "Id",
      columns: [
        {
          field: "ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("product/find", value),
        query: params => {
          if(props.customerId) params.condition = new Condition("CustomerId", Operator.Equal, props.customerId).and(params.condition!);
          return model!.dispatch("product/query", params)
        }
      }
    };

    // 單位價格 
    // 總金額= 單位價格 * 總下單件數
    watch(() => table.formData.UnitPrice, async(value) => {
      table.formData.TotleAmount = formatTotalAmount((value ?? 0), (table.formData.OrderQuantity ?? 0), 1000);
    }, { immediate: true });

    // 總下單件數 
    // 總金額 = 單位價格 * 總下單件數
    watch(() => table.formData.OrderQuantity, async(value) => {
      // console.log('OrderQuantity')
      table.formData.TotleAmount = formatTotalAmount((table.formData.UnitPrice ?? 0), (value ?? 0), 1000);      
      if(value && table.formData.Radix){
        table.formData.Quantity = value / table.formData.Radix;
      }
    }, { immediate: true });

    // 箱數 
    // 總下單件數 = 箱數 * 套/箱
    // 總卷數 = 箱數 * 套/箱 * 卷/套(產品)
    // CBM = 箱數 * 產品CBM
    watch(() => table.formData.Quantity, async(value) => {
      table.formData.OrderQuantity = value * table.formData.Radix; // Radix已不需要從product取得
      if(table.formData.ProductId) {
        const temp = await getProductDetails(table.formData.ProductId);
        if (temp) {
          // console.log('Quantity')
          table.formData.RollQuantity = value * table.formData.Radix * temp.Bundle;
          table.formData.CBM = formatCBMAmount(temp.CBM, (value ?? 0));
        }
      }
      table.formData.TotleGW = formatTotalAmount(value, table.formData.GW, 1000);
      table.formData.TotleNW = formatTotalAmount(value, table.formData.NW, 1000);
      table.formData.TotalWeight = table.formData.TotleNW;
    }, { immediate: true });

    // 套/箱
    // 總下單件數 = 箱數 * 套/箱
    // 總卷數 = 箱數 * 套/箱 * 卷/套(產品)
    watch(() => table.formData.Radix, async(value) => {
      table.formData.OrderQuantity = table.formData.Quantity * value; // Radix已不需要從product取得
      if(table.formData.ProductId) {
        const temp = await getProductDetails(table.formData.ProductId);
        if (temp) {
          // console.log('Radix')
          table.formData.RollQuantity = table.formData.Quantity * value * temp.Bundle;
        }
      }
    }, { immediate: true });

    // GW
    // TotleGW = GW * 箱數
    watch(() => table.formData.GW, async(value) => {
      table.formData.TotleGW = formatTotalAmount(table.formData.Quantity, value, 1000);
    }, { immediate: true });

    // NW
    // TotleNW = NW * 箱數
    watch(() => table.formData.NW, async(value) => {
      table.formData.TotleNW = formatTotalAmount(table.formData.Quantity, value, 1000);
      table.formData.TotalWeight = table.formData.TotleNW;
    }, { immediate: true });

    const getProductDetails = async (productId: any) => {
      const temp = await model?.dispatch("product/find", productId)
      return temp ? temp : null;
    }

    const customerProduct = async (data: any) => {
      if(data?.ProductId) {
        try {
          const temp = await model?.dispatch("product/find", data.ProductId)
          if (temp) {
            data.UnitPrice = temp.UnitPrice;
            data.Radix = temp.Radix;
            data.GW = temp.GW;
            data.NW = temp.NW;
            if(data.Quantity && data.Quantity > 0) data.CBM = formatCBMAmount(temp.CBM, (data.Quantity ?? 0));
          }
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const TF = [{ label: "是", value: true }, { label: "否", value: false }];

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      customerProduct,
      formatAmount,
      TF,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>
