
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import { formatAmount, formatTotalAmount, formatCBMAmount } from '@/libs/utils'

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    customerId: Number,
    item: [],
    visible: Boolean,
    readType: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: [{ field: 'Ordinal', order: 'asc' }]
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        // console.log(row)
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();

        emit('updateTotal');
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {};
        table.formData.OrderQuantity = 0;
        // table.formData.Radix = 0;
        table.formData.Quantity = 0;
        table.formData.UnitPrice = 0;
        table.formData.TotleAmount = 0;
        table.formData.GW = 0;
        table.formData.TotleGW = 0;
        table.formData.NW = 0;
        table.formData.TotleNW = 0;
        table.formData.CBM = 0;
        table.formData.Pallets = 0;
        table.formData.Radix = 0;
        table.formData.TotalWeight = 0;
        table.formData.RollQuantity = 0;
        table.formData.ThicknessDisplay = false;
        // table.formData.Long = 0;
        // table.formData.Width = 0;
        // table.formData.High = 0;
        table.formData.Ordinal = 0;
        table.selectedRow = null;
        table.isEditing = false;
      },
      async save() {
        //table.formData.ProductSize = `${table.formData.Long ? Number(table.formData.Long) : 0},${table.formData.Width ? Number(table.formData.Width) : 0},${table.formData.High ? Number(table.formData.High) : 0}`
        table.formData.Product = null;
        if(!table.formData.ProductId)
          return CloudFun.send('error', { subject: '執行失敗', content: '請選擇產品' })
        if (table.formData.ProductId) {
          const temp = await model?.dispatch("product/find", table.formData.ProductId)
          table.formData.Product = { Id: temp.Id, ProductName: temp.ProductName, ProductNo: temp.ProductNo };          
        }        
        if(table.isEditing) {
          // 編輯確認
          await Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          table.formData.Ordinal = getNextOrdinal();
          await grid.value.insert(table.formData);
        }
        table.reset();
        await grid.value.sort("Ordinal", 'asc');

        emit('updateTotal');
      },
      rules: {
        ProductNo: [{ required: true }],
        ProductName: [{ required: true }],
      }
    })

    const getNextOrdinal = () => {
      const data = grid.value.getTableData().fullData;
      if(!data || data.length == 0) return 1; 
      var maxOrdinal = Math.max(...data.map((e: any) => e.Ordinal));
      return (maxOrdinal ?? 0) + 1;
    }

    watch(() => props.aid, async(value) => {
      if(value) {
        // console.log(props.data)
        var t = Date.now();
        if(props.data && props.data.length > 0) {          
          table.data = props.data;
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    watch(() => props.item, async(value: any) => {
      if(value && value.length > 0) {
        for (let item of value) {
          await new Promise(resolve => {
            table.formData.ProductId = item.Product.Id;
            table.formData.TotleAmount = item.TotleAmount;
            table.formData.OrderQuantity = item.OrderQuantity;
            table.formData.Quantity = item.Quantity;
            table.formData.Remark = item.Remark;
            table.formData.UnitPrice = item.UnitPrice;
            // table.formData.ThicknessDisplay = item.ThicknessDisplay;
            table.formData.RollQuantity = item.RollQuantity;
            // table.formData.CBM = item.CBM;
            table.formData.Pallets = item.Pallets;
            table.formData.Radix = item.Radix;
            table.formData.GW = item.Product?.GW ?? 0;
            table.formData.NW = item.Product?.NW ?? 0;
            table.isEditing = false;
            setTimeout(() => {
              resolve(table.formData.CBM);
            }, 200);
          });
          await table.save();
        }
        // await value.forEach(async(e: any) => {
        //   table.formData.ProductId = e.Product.Id;
        //   // table.formData.ProductNo = e.Product?.ProductNo;
        //   // table.formData.ProductDescription = e.Product?.ProductDescription;
        //   // table.formData.PackagingDescription = e.Product?.PackagingDescription;
        //   // table.formData.ProductSize = e.Product?.ProductSize;
        //   // table.formData.UnitPrice = e.Product?.UnitPrice;
        //   table.formData.TotleAmount = e.TotleAmount;
        //   // table.formData.Radix = e.Product?.Radix;
        //   table.formData.OrderQuantity = e.OrderQuantity;
        //   table.formData.Quantity = e.Quantity;
        //   table.formData.Remark = e.Remark;
        //   table.isEditing = false;
        //   console.log(table.formData.ProductId, 'go1')
        //   await table.save();
        // });
        
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "ProductName",
      valueField: "Id",
      columns: [
        {
          field: "ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("product/find", value),
        query: params => {
          if(props.customerId) params.condition = new Condition("CustomerId", Operator.Equal, props.customerId).and(params.condition!);
          return model!.dispatch("product/query", params)
        }
      }
    };

    // 單位價格 
    // 總金額= 單位價格 * 總下單件數
    watch(() => table.formData.UnitPrice, async(value) => {
      table.formData.TotleAmount = formatTotalAmount((value ?? 0), (table.formData.OrderQuantity ?? 0), 1000);
    }, { immediate: true });

    // 總下單件數 
    // 總金額 = 單位價格 * 總下單件數
    watch(() => table.formData.OrderQuantity, async(value) => {
      // console.log('OrderQuantity')
      table.formData.TotleAmount = formatTotalAmount((table.formData.UnitPrice ?? 0), (value ?? 0), 1000);      
      if(value && table.formData.Radix){
        table.formData.Quantity = value / table.formData.Radix;
      }
    }, { immediate: true });

    // 箱數 
    // 總下單件數 = 箱數 * 套/箱
    // 總卷數 = 箱數 * 套/箱 * 卷/套(產品)
    // CBM = 箱數 * 產品CBM
    watch(() => table.formData.Quantity, async(value) => {
      table.formData.OrderQuantity = value * table.formData.Radix; // Radix已不需要從product取得
      if(table.formData.ProductId) {
        const temp = await getProductDetails(table.formData.ProductId);
        if (temp) {
          // console.log('Quantity')
          table.formData.RollQuantity = value * table.formData.Radix * temp.Bundle;
          table.formData.CBM = formatCBMAmount(temp.CBM, (value ?? 0));
        }
      }
      table.formData.TotleGW = formatTotalAmount(value, table.formData.GW, 1000);
      table.formData.TotleNW = formatTotalAmount(value, table.formData.NW, 1000);
      table.formData.TotalWeight = table.formData.TotleNW;
    }, { immediate: true });

    // 套/箱
    // 總下單件數 = 箱數 * 套/箱
    // 總卷數 = 箱數 * 套/箱 * 卷/套(產品)
    watch(() => table.formData.Radix, async(value) => {
      table.formData.OrderQuantity = table.formData.Quantity * value; // Radix已不需要從product取得
      if(table.formData.ProductId) {
        const temp = await getProductDetails(table.formData.ProductId);
        if (temp) {
          // console.log('Radix')
          table.formData.RollQuantity = table.formData.Quantity * value * temp.Bundle;
        }
      }
    }, { immediate: true });

    // GW
    // TotleGW = GW * 箱數
    watch(() => table.formData.GW, async(value) => {
      table.formData.TotleGW = formatTotalAmount(table.formData.Quantity, value, 1000);
    }, { immediate: true });

    // NW
    // TotleNW = NW * 箱數
    watch(() => table.formData.NW, async(value) => {
      table.formData.TotleNW = formatTotalAmount(table.formData.Quantity, value, 1000);
      table.formData.TotalWeight = table.formData.TotleNW;
    }, { immediate: true });

    const getProductDetails = async (productId: any) => {
      const temp = await model?.dispatch("product/find", productId)
      return temp ? temp : null;
    }

    const customerProduct = async (data: any) => {
      if(data?.ProductId) {
        try {
          const temp = await model?.dispatch("product/find", data.ProductId)
          if (temp) {
            data.UnitPrice = temp.UnitPrice;
            data.Radix = temp.Radix;
            data.GW = temp.GW;
            data.NW = temp.NW;
            if(data.Quantity && data.Quantity > 0) data.CBM = formatCBMAmount(temp.CBM, (data.Quantity ?? 0));
          }
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const TF = [{ label: "是", value: true }, { label: "否", value: false }];

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      customerProduct,
      formatAmount,
      TF,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
