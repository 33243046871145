
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import PackingListItemTable from "@/components/import-data/PackingListItemTable.vue";
import formatDate from 'xe-utils/toDateString'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { formatAmount } from '@/libs/utils'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    PackingListItemTable,
    loading: VueLoading,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    const isLoading = ref(false);
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]
    
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("PackingListCreate");
    const canUpdate = checkPermissions("PackingListUpdate");
    const canDelete = checkPermissions("PackingListDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '包裝資料管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      expand: true,
      printConfig: { sheetName: '包裝資料管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '包裝資料管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        // {
        //   field: 'ProductNo',
        //   title: '品號',
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   treeNode: false,
        // },
        {
          field: 'InvoiceNumber',
          title: 'PackingList No',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          slots: { default: 'link', }
        },
        {
          field: 'Customer.Number',
          title: '客戶代號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },
        {
          field: 'PackingListDate',
          title: 'Date',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        {
          field: 'Consignee',
          title: '收貨人',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },
        {
          field: "operate",
          title: "操作",
          align: "center",
          width: 150,
          slots: { default: "operate" }
        }
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          if(user && !user.IsAdmin) {
            params.condition = new Condition("EmployeeId.Value", Operator.Equal, user.Id).and(params.condition!);
          }
          return model.dispatch('packingList/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('packingList/query') : undefined,
        save: model ? (params) => model.dispatch('packingList/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true, fullscreen: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          title: '選擇項目',
          span: 24,
          slots: { default: 'column-Item' }
        },
        // {
        //   field: 'ProductNo',
        //   title: '品號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'InvoiceNumber',
          title: 'PackingList No',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'CustomerPO',
        //   title: '客戶代號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },        
        {
          field: 'CustomerId',
          title: '客戶',
          span: 12,
          slots: { default: "column-CustomerId" }
        },
        {
          field: 'EmployeeId',
          title: '負責業務',
          span: 12,
          slots: { default: "column-employeeId" }
        },
        {
          field: 'PackingListDate',
          title: 'Date',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'Consignee',
          title: '收貨人',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Shipper',
          title: '託運人',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'VesselNameVoyage',
          title: '船名航次',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'PortofLoading',
          title: '裝載港口',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'FinalDestination',
          title: '目的地',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ETD',
          title: 'ETD',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'ETA',
          title: 'ETA',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'Country',
          title: '原產國',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'TermDelivery',
          title: '貿易條件',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'SKU',
        //   title: 'SKU',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'Container',
        //   title: '櫃號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'Seal',
          title: 'Seal',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'ProductDescription',
        //   title: '產品說明',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'ProductSize',
        //   title: '產品尺寸',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'PackagingDescription',
        //   title: '包裝說明',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'ContainerNumber',
          title: 'Container Number',
          span: 12,
          titleWidth: 130,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'SealNumber',
        //   title: 'SealNumber',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'Radix',
        //   title: '套/箱',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'Quantity',
        //   title: '箱數',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'UnitPrice',
        //   title: '單位價格',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },        
        // {
        //   field: 'GW',
        //   title: '@G.W',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'TotleGW',
        //   title: 'TotleG.W',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'CBM',
        //   title: 'CBM (立方米)',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'Long',
        //   title: '長(cm)',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'Width',
        //   title: '寬(cm)',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'High',
        //   title: '高(cm)',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        {
          field: 'Status',
          title: '狀態',
          span: 12,
          itemRender: { name: "$select", props: {  }, options: model ? Object.values(model.enums.PackingListStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'ShippingMark',
          title: 'Shipping Mark',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, laceholder: '請輸入文字' } }
        },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } }
        },        
        {
          title: '項目資料',
          span: 24,
          slots: { default: 'column-PackingListItem' }
        },
        {
          field: 'Pallets',
          title: '總棧板數量',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'OrderQuantity',
          title: '總下單件數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'Quantity',
          title: '總箱數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字'} }
        },
        {
          field: 'CBM',
          title: 'CBM',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 4 } }
        },
        {
          title: '其他費用',
          span: 24,
        },
        {
          field: 'LicenseFee',
          title: '版費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'InspectionFee',
          title: '檢驗費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'ToolingFee',
          title: '模具費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'SampleFee',
          title: '樣品費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'ShippingFee',
          title: '運費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
      ],
      rules: {
        CustomerId: [{ required: true }],
        InvoiceNumber: [{ required: true }],
      }
    }

    const itemsTable = ref<any>();
    const formSubmit = (row: any, submit: any) => {
      var items = itemsTable?.value.getData();
      console.log(items)
      if(items)
        row.Items = items;
      
      submit();
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: (value: any) => model!.dispatch("employee/find", value), // eslint-disable-line
        query: (params: any) => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.condition!.and("Number", Operator.IsNotNull, null).and(new Condition("Number", Operator.Equal, "C004").or("Number", Operator.Equal, "C003").or("Number", Operator.Equal, "C020").or("Number", Operator.Equal, "C021"));
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const ItemSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "InvoiceNumber",
      valueField: "Id",
      columns: [
        {
          field: "InvoiceNumber",
          title: "Invoice No",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("invoice/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("invoice/query", params) // eslint-disable-line
        }
      }
    };

    const addItem = async (row: any) => {      
      try {
        var item = await model!.dispatch("invoice/find", row.ItemId);
        if(item?.Id) {
          // console.log(item)
          row.InvoiceNumber = item.InvoiceNumber;
          row.CustomerId = item.CustomerId;
          if(item.EmployeeId) row.EmployeeId = item.EmployeeId;
          row.PackingListDate = item.PIDate;
          row.Consignee = item.Consignee;
          row.Shipper = item.Shipper;
          row.VesselNameVoyage = item.VesselNameVoyage;
          row.PortofLoading = item.PortofLoading;
          row.FinalDestination = item.FinalDestination;
          row.ETD = item.ETD;
          row.ETA = item.ETA;
          row.Country = item.Country;
          row.TermDelivery = item.TermDelivery;
          if(item.Items) {            
            row.Item = item.Items;
            row.ItemId = null;
          } else {
            CloudFun.send('error', { subject: '執行失敗', content: '資料未包含項目資料' })
          }          
        }
      } catch (error: any) {
        CloudFun.send('error', { subject: '執行失敗', content: error })
      }
    }

    const initData = (row: any, callback: any) => {
      row.Quantity = 0;
      row.OrderQuantity = 0;
      row.TotleCBM = 0;
      row.TotleAmount = 0;

      row.LicenseFee = 0;
      row.InspectionFee = 0;
      row.ToolingFee = 0;
      row.SampleFee = 0;
      row.ShippingFee = 0;

      row.Items = [] as any;
      callback();
    }

    const s2ab = (s: any) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const exportExcel = async (row: any) => {
      try {
        isLoading.value = true;
        var data = await model!.dispatch('packingList/exportExcel', row.Id);
        const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = "包裝資料.xlsx";
        link.click();
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Number",
      valueField: "Id",
      columns: [
        {
          field: "Number",
          title: "客戶代號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },        
      ],
      showHeader: true,
      promises: {
        find: async (value: any) => await model!.dispatch("customer/find", value), // eslint-disable-line
        query: async (params: any) => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return await model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const exportReport = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/PackingListReportView?packingId=${row.Id}`)
    }

    const sumItems = (data: any, prop: string) => {
      if(data.length == 0) return 0;
      return data.map((e: any) => {
          return e[prop] ?? 0;
        })
        .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b));
    };

    const updateTotal = (data: any) => {
      const items = itemsTable?.value.getData();
      if(data && items) {
        data.OrderQuantity = sumItems(items, 'OrderQuantity'); //總下單件數
        data.TotleAmount = sumItems(items, 'TotleAmount'); //金額總計
        data.Pallets = sumItems(items, 'Pallets'); //總棧板數量
        data.Quantity = sumItems(items, 'Quantity'); //總箱數
        data.CBM = sumItems(items, 'CBM'); //CBM
      }
    }

    const linkVXEModal = ref<any>();
    const linkModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(data: any) {
        linkModal.visible = true;
        linkModal.selectedRow = data;
        linkVXEModal.value.maximize()
      },
      async save({ type }: any) {
        if (type >= 0) {
          try {
            await model?.dispatch('documentFlow/deleteAll', { type })
            linkModal.visible = false;
            grid.value.refresh();
          } catch (error: any) {
            CloudFun.send('error', { subject: '執行失敗', content: error })
          }
        } else {
          CloudFun.send('error', { subject: '執行失敗', content: '請確認刪除類型' })
        }
      }
    })

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      selectorOptions,
      initData,
      formSubmit,
      itemsTable,
      canCreate,
      ItemSelectorOptions,
      addItem,
      exportExcel,
      isLoading,
      customerSelectorOptions,
      exportReport,
      updateTotal,
      formatAmount,
      formatDate,
      linkVXEModal,
      linkModal,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
