<template>
  <loading loader="spinner"
    :active="isLoading"
    :can-cancel="true"
    :is-full-page="true"></loading>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">包裝資料管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing && canCreate" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入包裝資料" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <!-- <div class="flex mt-2 sm:mt-0">
          <button class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300" @click="grid.onPrint({ mode: 'curret' })">
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="initData"
          @removeSelectedRows="hideBatchDropDown()"
          @modalResize="onGridModalResize"
        >
          <template #link="{ row }">
            <div @click="linkModal.show(row)" class="inline-block bg-transparent text-blue-500 hover:underline cursor-pointer">
              <p>{{row.InvoiceNumber}}</p>
            </div>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="formSubmit(row, submit)"
            >
              <template #column-employeeId="{ data }">
                <select-box v-bind="selectorOptions" v-model="data.EmployeeId" />
              </template>
              <template #column-CustomerId="{ data }">
                <select-box v-bind="customerSelectorOptions" v-model="data.CustomerId" />
              </template>
              <template #column-Item="{ data }">
                <div class="grid grid-cols-6 gap-2">
                  <div class="col-span-5">
                    <select-box v-bind="ItemSelectorOptions" v-model="data.ItemId" />
                  </div>
                  <div>
                    <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="addItem(data)">
                      確認
                    </button>
                  </div>
                </div>
              </template>
              <template #column-PackingListItem="{ data }">
                <PackingListItemTable ref="itemsTable" :aid="data.Id" :item="data.Item" @refresh="refresh" :data="data.Items" :customerId="data.CustomerId" @updateTotal="updateTotal(data)" />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer="{ row }">
            <div class="flex justify-between">
              <div>
                <vxe-button v-if="row.Id" type="button" status="primary" content="匯出" @click="exportExcel(row)"></vxe-button>
              </div>
              <div>
                <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
                <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
              </div>
            </div>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{option.label}}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
          <template #operate="{ row }">
            <div class="flex justify-center">
              <button class="button rounded-full mr-1 bg-blue-600 text-white" title="編輯" @click.stop="grid.edit(row)">
                <FontAwesome icon="highlighter" class="w-4 h-4" />
              </button>
              <button
                class="button rounded-full mr-1 bg-blue-600 text-white" title="匯出" @click.stop="exportReport(row)">
                <FontAwesome icon="download" class="w-4 h-4" />
              </button>
              <button type="button" class="button rounded-full bg-red-600 text-white" title="刪除"
                @click.stop="grid.remove(row)">
                <FontAwesome icon="trash" class="w-4 h-4" />
              </button>
            </div>
          </template>
          <template #content="{ row }">
            <div class="m-5">
              <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                <thead>
                  <tr>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center" colspan="4">PackingList No：{{ row.InvoiceNumber }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">客戶</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Customer?.Name }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Date</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.PackingListDate ? formatDate(row.PackingListDate, 'yyyy/MM/dd') : "" }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">收貨人</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Consignee }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">託運人</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Shipper }}</td>
                  </tr>
                  <!-- <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">船名航次</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.VesselNameVoyage }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">裝載港口</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.PortofLoading }}</td>
                  </tr> -->
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">目的地</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.FinalDestination }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">ETD</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.ETD ? formatDate(row.ETD, 'yyyy/MM/dd') : "" }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">ETA</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.ETA ? formatDate(row.ETA, 'yyyy/MM/dd') : "" }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">貿易條件</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.TermDelivery }}</td>
                  </tr>
                  <!-- <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">原產國</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Country }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">SKU</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.SKU }}</td>
                  </tr> -->
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">櫃號</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Container }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Seal Number</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.SealNumber }}</td>

                  </tr>
                  <!-- <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">產品說明</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.ProductDescription }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">產品尺寸</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.ProductSize }}</td>
                  </tr> -->
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Container Number</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.ContainerNumber }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Order Number</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.OrderNumber }}</td>
                  </tr>
                  <!-- <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Seal</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Seal }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">套/箱</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Radix }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">包裝說明</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.PackagingDescription }}</td>
                  </tr> -->
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總毛重</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.TotleGW }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總箱數</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Quantity }}</td>
                    <!-- <td class="border border-gray-400 bg-gray-200 py-2 px-4">單位價格</td>
                    <td class="border border-gray-400 py-2 px-4">{{ formatAmount(row.UnitPrice) }}</td> -->
                  </tr>
                  <!-- <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">@G.W</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.GW }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">TotleG.W</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.TotleGW }}</td>
                  </tr> -->
                  <!-- <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">CBM(立方米)</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.CBM }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">長(cm)</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Long }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">寬(cm)</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Width }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">高(cm)</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.High }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">狀態</td>
                    <td class="border border-gray-400 py-2 px-4">{{ Object.values(model.enums.PackingListStatus).find(e => e.Value === row.Status)?.Name }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">備註</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Remark }}</td>
                  </tr> -->
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總棧板數量</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Pallets }}</td>
                    <!-- <td class="border border-gray-400 bg-gray-200 py-2 px-4">總下單件數</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.OrderQuantity }}</td> -->
                  </tr>
                  <!-- <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總金額</td>
                    <td class="border border-gray-400 py-2 px-4">{{ formatAmount(row.TotleAmount) }}</td>
                  </tr> -->
                </tbody>
              </table>
              <!-- <div class="mt-5 ml-1 mb-1">項目資料：</div> -->
              <!-- <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                <thead>
                  <tr>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">品號</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">品名</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">箱數</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">套/箱</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總下單件數</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總卷數</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總棧板數量</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">@G.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">TotleG.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">@N.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">TotleN.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總重量</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">CBM (立方米)</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">顯示厚度</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">備註</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in row.Items" :key="item.Id">
                    <td class="border border-gray-400 py-2 px-4">{{ item.Product?.ProductNo }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Product?.ProductName }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Quantity }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Radix }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.OrderQuantity }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.RollQuantity }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Pallets }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.GW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.TotleGW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.NW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.TotleNW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.TotalWeight }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.CBM }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.ThicknessDisplay == true ? '是' : item.ThicknessDisplay == false ? '否' : '不選擇' }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Remark }}</td>
                  </tr>
                </tbody>
              </table> -->
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal ref="linkVXEModal" v-model="linkModal.visible" fullscreen title="顯示包裝資料" show-zoom="true">
      <div class="m-5">
              <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                <thead>
                  <tr>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center" colspan="4">PackingList No：{{ linkModal.selectedRow?.InvoiceNumber }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">客戶</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Customer?.Name }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Date</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.PackingListDate ? formatDate(linkModal.selectedRow?.PackingListDate, 'yyyy/MM/dd') : "" }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">收貨人</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Consignee }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">託運人</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Shipper }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">船名航次</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.VesselNameVoyage }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">裝載港口</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.PortofLoading }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">目的地</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.FinalDestination }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">ETD</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.ETD ? formatDate(linkModal.selectedRow?.ETD, 'yyyy/MM/dd') : "" }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">ETA</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.ETA ? formatDate(linkModal.selectedRow?.ETA, 'yyyy/MM/dd') : "" }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">原產國</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Country }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">貿易條件</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.TermDelivery }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">SKU</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.SKU }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">櫃號</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Container }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Seal</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Seal }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">產品說明</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.ProductDescription }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">產品尺寸</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.ProductSize }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">包裝說明</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.PackagingDescription }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Container Number</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.ContainerNumber }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Seal Number</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.SealNumber }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">套/箱</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Radix }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">箱數</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Quantity }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">單位價格</td>
                    <td class="border border-gray-400 py-2 px-4">{{ formatAmount(linkModal.selectedRow?.UnitPrice) }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">@G.W</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.GW }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">TotleG.W</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.TotleGW }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">CBM(立方米)</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.CBM }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">長(cm)</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Long }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">寬(cm)</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Width }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">高(cm)</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.High }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">狀態</td>
                    <td class="border border-gray-400 py-2 px-4">{{ Object.values(model.enums.PackingListStatus).find(e => e.Value === linkModal.selectedRow?.Status)?.Name }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">備註</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Remark }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總棧板數量</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Pallets }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總下單件數</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.OrderQuantity }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">總金額</td>
                    <td class="border border-gray-400 py-2 px-4">{{ formatAmount(linkModal.selectedRow?.TotleAmount) }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="mt-5 ml-1 mb-1">項目資料：</div>
              <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                <thead>
                  <tr>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">品號</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">品名</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">箱數</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">套/箱</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總下單件數</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總卷數</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總棧板數量</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">@G.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">TotleG.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">@N.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">TotleN.W</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總重量</th>
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">CBM (立方米)</th>
                    <!-- <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">顯示厚度</th> -->
                    <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">備註</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in linkModal.selectedRow?.Items" :key="item.Id">
                    <td class="border border-gray-400 py-2 px-4">{{ item.Product?.ProductNo }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Product?.ProductName }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Quantity }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Radix }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.OrderQuantity }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.RollQuantity }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.Pallets }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.GW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.TotleGW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.NW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.TotleNW }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.TotalWeight }}</td>
                    <td class="border border-gray-400 py-2 px-4">{{ item.CBM }}</td>
                    <!-- <td class="border border-gray-400 py-2 px-4">{{ item.ThicknessDisplay == true ? '是' : item.ThicknessDisplay == false ? '否' : '不選擇' }}</td> -->
                    <td class="border border-gray-400 py-2 px-4">{{ item.Remark }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
    </vxe-modal>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import PackingListItemTable from "@/components/import-data/PackingListItemTable.vue";
import formatDate from 'xe-utils/toDateString'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { formatAmount } from '@/libs/utils'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    PackingListItemTable,
    loading: VueLoading,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    const isLoading = ref(false);
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]
    
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("PackingListCreate");
    const canUpdate = checkPermissions("PackingListUpdate");
    const canDelete = checkPermissions("PackingListDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '包裝資料管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      expand: true,
      printConfig: { sheetName: '包裝資料管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '包裝資料管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        // {
        //   field: 'ProductNo',
        //   title: '品號',
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   treeNode: false,
        // },
        {
          field: 'InvoiceNumber',
          title: 'PackingList No',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          slots: { default: 'link', }
        },
        {
          field: 'Customer.Number',
          title: '客戶代號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },
        {
          field: 'PackingListDate',
          title: 'Date',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        {
          field: 'Consignee',
          title: '收貨人',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },
        {
          field: "operate",
          title: "操作",
          align: "center",
          width: 150,
          slots: { default: "operate" }
        }
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          if(user && !user.IsAdmin) {
            params.condition = new Condition("EmployeeId.Value", Operator.Equal, user.Id).and(params.condition!);
          }
          return model.dispatch('packingList/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('packingList/query') : undefined,
        save: model ? (params) => model.dispatch('packingList/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true, fullscreen: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          title: '選擇項目',
          span: 24,
          slots: { default: 'column-Item' }
        },
        // {
        //   field: 'ProductNo',
        //   title: '品號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'InvoiceNumber',
          title: 'PackingList No',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'CustomerPO',
        //   title: '客戶代號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },        
        {
          field: 'CustomerId',
          title: '客戶',
          span: 12,
          slots: { default: "column-CustomerId" }
        },
        {
          field: 'EmployeeId',
          title: '負責業務',
          span: 12,
          slots: { default: "column-employeeId" }
        },
        {
          field: 'PackingListDate',
          title: 'Date',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'Consignee',
          title: '收貨人',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Shipper',
          title: '託運人',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'VesselNameVoyage',
          title: '船名航次',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'PortofLoading',
          title: '裝載港口',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'FinalDestination',
          title: '目的地',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ETD',
          title: 'ETD',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'ETA',
          title: 'ETA',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'Country',
          title: '原產國',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'TermDelivery',
          title: '貿易條件',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'SKU',
        //   title: 'SKU',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'Container',
        //   title: '櫃號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'Seal',
          title: 'Seal',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'ProductDescription',
        //   title: '產品說明',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'ProductSize',
        //   title: '產品尺寸',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'PackagingDescription',
        //   title: '包裝說明',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'ContainerNumber',
          title: 'Container Number',
          span: 12,
          titleWidth: 130,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'SealNumber',
        //   title: 'SealNumber',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'Radix',
        //   title: '套/箱',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'Quantity',
        //   title: '箱數',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'UnitPrice',
        //   title: '單位價格',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },        
        // {
        //   field: 'GW',
        //   title: '@G.W',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'TotleGW',
        //   title: 'TotleG.W',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'CBM',
        //   title: 'CBM (立方米)',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'Long',
        //   title: '長(cm)',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'Width',
        //   title: '寬(cm)',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'High',
        //   title: '高(cm)',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' } }
        // },
        {
          field: 'Status',
          title: '狀態',
          span: 12,
          itemRender: { name: "$select", props: {  }, options: model ? Object.values(model.enums.PackingListStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'ShippingMark',
          title: 'Shipping Mark',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, laceholder: '請輸入文字' } }
        },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } }
        },        
        {
          title: '項目資料',
          span: 24,
          slots: { default: 'column-PackingListItem' }
        },
        {
          field: 'Pallets',
          title: '總棧板數量',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'OrderQuantity',
          title: '總下單件數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'Quantity',
          title: '總箱數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字'} }
        },
        {
          field: 'CBM',
          title: 'CBM',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 4 } }
        },
        {
          title: '其他費用',
          span: 24,
        },
        {
          field: 'LicenseFee',
          title: '版費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'InspectionFee',
          title: '檢驗費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'ToolingFee',
          title: '模具費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'SampleFee',
          title: '樣品費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'ShippingFee',
          title: '運費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
      ],
      rules: {
        CustomerId: [{ required: true }],
        InvoiceNumber: [{ required: true }],
      }
    }

    const itemsTable = ref<any>();
    const formSubmit = (row: any, submit: any) => {
      var items = itemsTable?.value.getData();
      console.log(items)
      if(items)
        row.Items = items;
      
      submit();
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: (value: any) => model!.dispatch("employee/find", value), // eslint-disable-line
        query: (params: any) => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.condition!.and("Number", Operator.IsNotNull, null).and(new Condition("Number", Operator.Equal, "C004").or("Number", Operator.Equal, "C003").or("Number", Operator.Equal, "C020").or("Number", Operator.Equal, "C021"));
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const ItemSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "InvoiceNumber",
      valueField: "Id",
      columns: [
        {
          field: "InvoiceNumber",
          title: "Invoice No",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("invoice/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("invoice/query", params) // eslint-disable-line
        }
      }
    };

    const addItem = async (row: any) => {      
      try {
        var item = await model!.dispatch("invoice/find", row.ItemId);
        if(item?.Id) {
          // console.log(item)
          row.InvoiceNumber = item.InvoiceNumber;
          row.CustomerId = item.CustomerId;
          if(item.EmployeeId) row.EmployeeId = item.EmployeeId;
          row.PackingListDate = item.PIDate;
          row.Consignee = item.Consignee;
          row.Shipper = item.Shipper;
          row.VesselNameVoyage = item.VesselNameVoyage;
          row.PortofLoading = item.PortofLoading;
          row.FinalDestination = item.FinalDestination;
          row.ETD = item.ETD;
          row.ETA = item.ETA;
          row.Country = item.Country;
          row.TermDelivery = item.TermDelivery;
          if(item.Items) {            
            row.Item = item.Items;
            row.ItemId = null;
          } else {
            CloudFun.send('error', { subject: '執行失敗', content: '資料未包含項目資料' })
          }          
        }
      } catch (error: any) {
        CloudFun.send('error', { subject: '執行失敗', content: error })
      }
    }

    const initData = (row: any, callback: any) => {
      row.Quantity = 0;
      row.OrderQuantity = 0;
      row.TotleCBM = 0;
      row.TotleAmount = 0;

      row.LicenseFee = 0;
      row.InspectionFee = 0;
      row.ToolingFee = 0;
      row.SampleFee = 0;
      row.ShippingFee = 0;

      row.Items = [] as any;
      callback();
    }

    const s2ab = (s: any) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const exportExcel = async (row: any) => {
      try {
        isLoading.value = true;
        var data = await model!.dispatch('packingList/exportExcel', row.Id);
        const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = "包裝資料.xlsx";
        link.click();
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Number",
      valueField: "Id",
      columns: [
        {
          field: "Number",
          title: "客戶代號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },        
      ],
      showHeader: true,
      promises: {
        find: async (value: any) => await model!.dispatch("customer/find", value), // eslint-disable-line
        query: async (params: any) => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return await model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const exportReport = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/PackingListReportView?packingId=${row.Id}`)
    }

    const sumItems = (data: any, prop: string) => {
      if(data.length == 0) return 0;
      return data.map((e: any) => {
          return e[prop] ?? 0;
        })
        .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b));
    };

    const updateTotal = (data: any) => {
      const items = itemsTable?.value.getData();
      if(data && items) {
        data.OrderQuantity = sumItems(items, 'OrderQuantity'); //總下單件數
        data.TotleAmount = sumItems(items, 'TotleAmount'); //金額總計
        data.Pallets = sumItems(items, 'Pallets'); //總棧板數量
        data.Quantity = sumItems(items, 'Quantity'); //總箱數
        data.CBM = sumItems(items, 'CBM'); //CBM
      }
    }

    const linkVXEModal = ref<any>();
    const linkModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(data: any) {
        linkModal.visible = true;
        linkModal.selectedRow = data;
        linkVXEModal.value.maximize()
      },
      async save({ type }: any) {
        if (type >= 0) {
          try {
            await model?.dispatch('documentFlow/deleteAll', { type })
            linkModal.visible = false;
            grid.value.refresh();
          } catch (error: any) {
            CloudFun.send('error', { subject: '執行失敗', content: error })
          }
        } else {
          CloudFun.send('error', { subject: '執行失敗', content: '請確認刪除類型' })
        }
      }
    })

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      selectorOptions,
      initData,
      formSubmit,
      itemsTable,
      canCreate,
      ItemSelectorOptions,
      addItem,
      exportExcel,
      isLoading,
      customerSelectorOptions,
      exportReport,
      updateTotal,
      formatAmount,
      formatDate,
      linkVXEModal,
      linkModal,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
